<template>
	<div class="layout-navbars-breadcrumb">
		<i
			class="layout-navbars-breadcrumb-icon"
			:class="getThemeConfig.isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
			@click="onThemeConfigChange"
		></i>
		<el-breadcrumb class="app-breadcrumb" separator="/">
			<transition-group name="breadcrumb">
				<el-breadcrumb-item v-for="(item, index) in levelList" :key="item.path">
					<span v-if="item.redirect === 'noRedirect' || index == levelList.length - 1" class="no-redirect"> {{ item.meta.title }} </span>
					<a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a>
				</el-breadcrumb-item>
			</transition-group>
		</el-breadcrumb>
	</div>
</template>

<script>
import { Local } from '@/utils/storage.js';
import pathToRegexp from 'path-to-regexp';

export default {
	name: 'layoutBreadcrumb',
	data() {
		return {
			levelList: null,
		};
	},
	computed: {
		// 获取布局配置信息
		getThemeConfig() {
			return this.$store.state.themeConfig.themeConfig;
		},
	},
	created() {
		this.getBreadcrumb();
	},
	methods: {
		getBreadcrumb() {
			let matched = this.$route.matched.filter((item) => item.meta && item.meta.title);
			const first = matched[0];
			if (!this.isDashboard(first)) {
				matched = [].concat(matched);
			}
			this.levelList = matched.filter((item) => item.meta && item.meta.title && item.meta.breadcrumb !== false);
		},
		isDashboard(route) {
			const name = route && route.name;
			if (!name) {
				return false;
			}
			return name.trim().toLocaleLowerCase() === 'Dashboard'.toLocaleLowerCase();
		},
		pathCompile(path) {
			const { params } = this.$route;
			var toPath = pathToRegexp.compile(path);
			return toPath(params);
		},
		handleLink(item) {
			const { redirect, path } = item;
			if (redirect) {
				this.$router.push(redirect);
				return;
			}
			this.$router.push(this.pathCompile(path));
		},
		// breadcrumb icon 点击菜单展开与收起
		onThemeConfigChange() {
			this.$store.state.themeConfig.themeConfig.isCollapse = !this.$store.state.themeConfig.themeConfig.isCollapse;
			this.setLocalThemeConfig();
		},
		// 存储布局配置
		setLocalThemeConfig() {
			Local.remove('themeConfigPrev');
			Local.set('themeConfigPrev', this.$store.state.themeConfig.themeConfig);
		},
	},
	// 监听路由的变化
	watch: {
		$route: {
			handler(newVal) {
				this.getBreadcrumb();
			},
			deep: true,
		},
	},
};
</script>

<style scoped lang="scss">
.el-breadcrumb {
	font-size: 14px;
}
.el-icon-s-fold {
	font-size: 18px;
}
.layout-navbars-breadcrumb {
	flex: 1;
	height: inherit;
	display: flex;
	align-items: center;
	padding-left: 15px;
	background-color: #fff;
	padding: 10px 0;

	.layout-navbars-breadcrumb-icon {
		cursor: pointer;
		font-size: 18px;
		margin-right: 15px;
		color: var(--prev-bg-topBarColor);
		opacity: 0.8;
		margin-left: 14px;
		line-height: 18px;
		&:hover {
			opacity: 1;
		}
	}
	.layout-navbars-breadcrumb-span {
		opacity: 0.7;
		font-size: 16px;
		color: var(--prev-color-primary);
	}
	.layout-navbars-breadcrumb-a {
		opacity: 0.7;
		font-size: 16px;
		color: var(--prev-bg-topBarColor);
	}
	.layout-navbars-breadcrumb-iconfont {
		font-size: 16px;
		margin-right: 5px;
	}
}
/deep/ .no-redirect {
	color: #666666;
}
</style>
