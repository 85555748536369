export default [
	{
		path: '/home',
		name: 'home',
		meta: {
			title: '数据驾驶舱',
			icon: 'iconfont icon-shujujiashicang',
		},
	},
	{
		path: '/institutionManager',
		name: 'institutionManager',
		meta: {
			title: '机构管理',
			icon: 'iconfont icon-jigouguanli',
		},
		children: [
			{
				path: '/institutionManager/countyMedicalManager',
				name: 'countyMedicalManager',
				meta: {
					title: '医疗机构管理',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/institutionManager/medicalAndHealth',
				name: 'medicalAndHealth',
				meta: {
					title: '医疗卫生机构管理',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			// {
			// 	path: '/institutionManager/villageMedicalManager',
			// 	name: 'villageMedicalManager',
			// 	meta: {
			// 		title: '村卫生室信息管理',
			// 		icon: 'iconfont icon-fuzhiyemian',
			// 	},
			// },
			// {
			// 	path: '/institutionManager/communityManager',
			// 	name: 'communityManager',
			// 	meta: {
			// 		title: '社区管理',
			// 		icon: 'iconfont icon-fuzhiyemian',
			// 	},
			// },
			// {
			// 	path: '/institutionManager/departmentManager',
			// 	name: 'departmentManager',
			// 	meta: {
			// 		title: '科室管理',
			// 		icon: 'iconfont icon-fuzhiyemian',
			// 	},
			// },
			// {
			// 	path: '/institutionManager/postManager',
			// 	name: 'postManager',
			// 	meta: {
			// 		title: '岗位管理',
			// 		icon: 'iconfont icon-fuzhiyemian',
			// 	},
			// },
			// {
			// 	path: '/institutionManager/titleManager',
			// 	name: 'titleManager',
			// 	meta: {
			// 		title: '职称管理',
			// 		icon: 'iconfont icon-fuzhiyemian',
			// 	},
			// },
			{
				path: '/institutionManager/medicalStaff',
				name: 'medicalStaff',
				meta: {
					title: '医护人员管理',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
		],
	},
	{
		path: '/communityManager',
		name: 'communityManager',
		meta: {
			title: '社区管理',
			icon: 'iconfont icon-shequ',
		},
		children: [
			{
				path: '/communityManager/community',
				name: 'community',
				meta: {
					title: '社区信息管理',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/communityManager/familyInfo',
				name: 'familyInfo',
				meta: {
					title: '社区居民信息管理',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
		],
	},
	{
		path: '/userManager',
		name: 'userManager',
		meta: {
			title: '群众管理',
			icon: 'iconfont icon-qunzhong',
		},
		children: [
			{
				path: '/userManager/familyInfo',
				name: 'familyInfo',
				meta: {
					title: '家庭信息',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
		],
	},
	{
		path: '/publicHealth',
		name: 'publicHealth',
		meta: {
			title: '公共卫生',
			icon: 'iconfont icon-gonggongweisheng-1',
		},
		children: [
			{
				path: '/publicHealth/healthyText',
				name: 'healthyText',
				meta: {
					title: '健康档案',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/publicHealth/elderlyHealth',
				name: 'elderlyHealth',
				meta: {
					title: '老年人健康',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/publicHealth/infectiousDisease',
				name: 'infectiousDisease',
				meta: {
					title: '传染病',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/publicHealth/publicEmergencies',
				name: 'publicEmergencies',
				meta: {
					title: '公共突发事件',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/publicHealth/healthEducation',
				name: 'healthEducation',
				meta: {
					title: '健康教育活动',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/publicHealth/inoculateAgainst',
				name: 'inoculateAgainst',
				meta: {
					title: '接种预防',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/publicHealth/healthSupervision',
				name: 'healthSupervision',
				meta: {
					title: '卫生计生监管',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
		],
	},
	{
		path: '/basicMedical',
		name: 'basicMedical',
		meta: {
			title: '基本医疗',
			icon: 'iconfont icon-xunzhentongji',
		},
		children: [
			{
				path: '/basicMedical/receivingHistory',
				name: 'receivingHistory',
				meta: {
					title: '接转诊记录',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/basicMedical/inspectHistory',
				name: 'inspectHistory',
				meta: {
					title: '检查检验',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			// {
			// 	path: '/basicMedical/TCM',
			// 	name: 'TCM',
			// 	meta: {
			// 		title: '中医医疗',
			// 		icon: 'iconfont icon-fuzhiyemian',
			// 	},
			// },
		],
	},
	{
		path: '/patrolWorkArrangement',
		name: 'patrolWorkArrangement',
		meta: {
			title: '巡诊驻派管理',
			icon: 'iconfont icon-xunzhengongzuoanpai',
		},
		children: [
			{
				path: '/patrolWorkArrangement/carManagement',
				name: 'carManagement',
				meta: {
					title: '车辆管理',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/patrolWorkArrangement/deviceManagement',
				name: 'deviceManagement',
				meta: {
					title: '设备管理',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			// {
			// 	path: '/patrolWorkArrangement/drugManagement',
			// 	name: 'drugManagement',
			// 	meta: {
			// 		title: '药品管理',
			// 		icon: 'iconfont icon-fuzhiyemian',
			// 	},
			// },
			{
				path: '/patrolWorkArrangement/patrolTeam',
				name: 'patrolTeam',
				meta: {
					title: '巡诊组管理',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/patrolWorkArrangement/workMoudle',
				name: 'workMoudle',
				meta: {
					title: '巡诊工作模块管理',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/patrolWorkArrangement/visitPlan',
				name: 'visitPlan',
				meta: {
					title: '巡诊计划',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/patrolWorkArrangement/residencyTeam',
				name: 'residencyTeam',
				meta: {
					title: '驻派组管理',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/patrolWorkArrangement/residencyWorkMoudle',
				name: 'residencyWorkMoudle',
				meta: {
					title: '驻派工作模块管理',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/patrolWorkArrangement/residencyPlan',
				name: 'residencyPlan',
				meta: {
					title: '驻派计划',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			// {
			// 	path: '/patrolWorkArrangement/jobChange',
			// 	name: 'jobChange',
			// 	meta: {
			// 		title: '换岗申请',
			// 		icon: 'iconfont icon-fuzhiyemian',
			// 	},
			// },
		],
	},
	{
		path: '/familyDoctorManager',
		name: 'familyDoctorManager',
		meta: {
			title: '家庭签约医生',
			icon: 'iconfont icon-jiatingqianyueyisheng',
		},
		children: [
			// {
			// 	path: '/familyDoctorManager/serviceInstructions',
			// 	name: 'serviceInstructions',
			// 	meta: {
			// 		title: '服务须知',
			// 		icon: 'iconfont icon-fuzhiyemian',
			// 	},
			// },
			{
				path: '/familyDoctorManager/contractManager',
				name: 'contractManager',
				meta: {
					title: '服务条款',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			// {
			// 	path: '/familyDoctorManager/serviceContent',
			// 	name: 'serviceContent',
			// 	meta: {
			// 		title: '服务内容及条款',
			// 		icon: 'iconfont icon-fuzhiyemian',
			// 	},
			// },
			{
				path: '/familyDoctorManager/healthService',
				name: 'healthService',
				meta: {
					title: '卫生服务团队管理',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			// {
			// 	path: '/familyDoctorManager/doctorService',
			// 	name: 'doctorService',
			// 	meta: {
			// 		title: '家庭签约医生服务请求',
			// 		icon: 'iconfont icon-fuzhiyemian',
			// 	},
			// },
			{
				path: '/familyDoctorManager/termManagement',
				name: 'termManagement',
				meta: {
					title: '合同条款管理',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/familyDoctorManager/healthEducation',
				name: 'healthEducation',
				meta: {
					title: '健康教育',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/familyDoctorManager/signingManager',
				name: 'signingManager',
				meta: {
					title: '签约管理',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/familyDoctorManager/physicalExaminationManager',
				name: 'physicalExaminationManager',
				meta: {
					title: '体检管理',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/familyDoctorManager/healthFollowUp',
				name: 'healthFollowUp',
				meta: {
					title: '健康随访',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			// {
			// 	path: '/familyDoctorManager/healthCounseling',
			// 	name: 'healthCounseling',
			// 	meta: {
			// 		title: '健康咨询',
			// 		icon: 'iconfont icon-fuzhiyemian',
			// 	},
			// },
		],
	},
	{
		path: '/healthActivity',
		name: 'healthActivity',
		meta: {
			title: '健康活动',
			icon: 'iconfont icon-jiankanghuodong',
		},
		children: [
			{
				path: '/healthActivity/physicalActivity',
				name: 'physicalActivity',
				meta: {
					title: '健康活动',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
		],
	},
	{
		path: '/trainingOrganization',
		name: 'trainingOrganization',
		meta: {
			title: '培训组织服务',
			icon: 'iconfont icon-peixunzuzhifuwu',
		},
		children: [
			{
				path: '/trainingOrganization/traningManagement',
				name: 'traningManagement',
				meta: {
					title: '业务培训',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
		],
	},
	{
		path: '/performanceManager',
		name: 'performanceManager',
		meta: {
			title: '绩效管理',
			icon: 'iconfont icon-jigoutongji',
		},
		children: [
			{
				path: '/performanceManager/index',
				name: 'index',
				meta: {
					title: '医疗机构绩效评价',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
		],
	},
	{
		path: '/staticTotalView',
		name: 'staticTotalView',
		meta: {
			title: '基础数据统计',
			icon: 'iconfont icon-shujutongji',
		},
		children: [
			{
				path: '/staticTotalView/userInfo',
				name: 'userInfo',
				meta: {
					title: '用户信息统计',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/staticTotalView/familyInfo',
				name: 'familyInfo',
				meta: {
					title: '家庭信息统计',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/staticTotalView/institutionalStatistics',
				name: 'institutionalStatistics',
				meta: {
					title: '机构统计',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},

			{
				path: '/staticTotalView/resourceStatic',
				name: 'resourceStatic',
				meta: {
					title: '资源统计',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			// {
			// 	path: '/staticTotalView/healthEqu',
			// 	name: 'healthEqu',
			// 	meta: {
			// 		title: '康养设备统计',
			// 		icon: 'iconfont icon-fuzhiyemian',
			// 	},
			// },
			{
				path: '/staticTotalView/medicalStaffStatistics',
				name: 'medicalStaffStatistics',
				meta: {
					title: '医护人员统计',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/staticTotalView/patrolStatistics',
				name: 'patrolStatistics',
				meta: {
					title: '巡诊统计',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/staticTotalView/residentStatistics',
				name: 'residentStatistics',
				meta: {
					title: '驻派统计',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/staticTotalView/childImmunity',
				name: 'childImmunity',
				meta: {
					title: '接种预防统计',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/staticTotalView/publicEmergencies',
				name: 'publicEmergencies',
				meta: {
					title: '公共突发事件统计',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/staticTotalView/familyDoctor',
				name: 'familyDoctor',
				meta: {
					title: '家庭签约医生统计',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
		],
	},
	{
		path: '/staticManager',
		name: 'staticManager',
		meta: {
			title: '数据分析',
			icon: 'iconfont icon-shujufenxi',
		},
		children: [
			{
				path: '/staticManager/medicalStaffData',
				name: 'medicalStaffData',
				meta: {
					title: '医护人员数据分析',
				},
			},
			{
				path: '/staticManager/importPersonData',
				name: 'importPersonData',
				meta: {
					title: '重点人群数据分析',
				},
			},
			{
				path: '/staticManager/infectiousDiseaseData',
				name: 'infectiousDiseaseData',
				meta: {
					title: '传染病数据分析',
				},
			},
			{
				path: '/staticManager/healthEduData',
				name: 'healthEduData',
				meta: {
					title: '健康教育数据分析',
				},
			},
			// {
			// 	path: '/staticManager/receiveData',
			// 	name: 'receiveData',
			// 	meta: {
			// 		title: '接转诊数据分析',
			// 	},
			// },
			{
				path: '/staticManager/inspectData',
				name: 'inspectData',
				meta: {
					title: '检查检验数据分析',
				},
			},
			// {
			// 	path: '/staticManager/makeAroundData',
			// 	name: 'makeAroundData',
			// 	meta: {
			// 		title: '巡诊数据分析',
			// 	},
			// },
			// {
			// 	path: '/staticManager/residencyData',
			// 	name: 'residencyData',
			// 	meta: {
			// 		title: '驻派分析',
			// 	},
			// },
			{
				path: '/staticManager/deviceData',
				name: 'deviceData',
				meta: {
					title: '康养设备数据分析',
				},
			},
		],
	},
];
